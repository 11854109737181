// app/javascript/controllers/glide_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static values = { id: Number }
  static targets = [ "count", "like", "dislike" ]

  like() {
    fetch(`/v2/articles/${this.idValue}/like`, {
      method: "POST",
      headers: {
      "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
      "Content-Type": "application/json"
      }
    })
    .then(response => response.json())
    .then(data => { this.countTarget.textContent = data; })
    .catch(error => console.error('Error:', error));
  }
  
  dislike() {
    fetch(`/v2/articles/${this.idValue}/dislike`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
        "Content-Type": "application/json"
      }
    })
    .then(response => response.json())
    .then(data => { this.countTarget.textContent = data; })
    .catch(error => console.error('Error:', error));
  }
}